<template>
  <div>
    <p>
      <v-icon color="error">{{$icon('i.Error')}}</v-icon>
      {{$t("t.CriticalError")}}
    </p>
    <p>
      {{$t("t.AuthenticationClaimValueNotMapped", { value: this.claimValue })}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ExternalLoginUserNotMap',
  data () {
    return {
      claimValue: this.$route.query.claimValue
    }
  }
}
</script>
